import React, { lazy, Suspense } from 'react'

import './application.css'

const ApplicationCmp = lazy(() => import('./application'));
const LoginCmp = lazy(() => import('./login'));
const RecoveryCmp = lazy(() => import('./recovery'));

export const Application = () => {
  return <Suspense fallback={null}><ApplicationCmp /></Suspense>
}

export const Login = () => {
  return <Suspense fallback={null}><LoginCmp /></Suspense>
}

export const Recovery = () => {
  return <Suspense fallback={null}><RecoveryCmp /></Suspense>
}
