import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import nl from './nl';

export const Languages = {
  en: { nativeName: 'English' },
  nl: { nativeName: 'Nederlands' },
}

export type Language = keyof typeof Languages;

export const i18nInit = () => i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'nl',
    lng: 'nl',
    resources: {
      en: { translation: en },
      nl: { translation: nl }
    },
  });
