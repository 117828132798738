type CallbackFn = (...args: any) => void
export type AnyCallbackFn = (event: string, ...args: any) => void
export interface Callbacks {[event: string]: CallbackFn[] }

export interface Emitter {
  emit: (event: string, ...args: any) => void
  on: (event: string, cb: CallbackFn) => void
  onAny: (cb: AnyCallbackFn) => void
  off: (event: string, cb: CallbackFn) => void
  offAny: (cb: AnyCallbackFn) => void
  reset: (event?: string) => void
}

export const emitter = (anySkipsOn: boolean = true): Emitter => {
  const callbacks: Callbacks = {};
  const anyCallbacks: AnyCallbackFn[] = [];

  const emit: Emitter['emit'] = (event, ...args) => {
    if (callbacks[event]) {
      for (const cb of callbacks[event]) {
        cb(...args);
      }
    }
    if (!anySkipsOn || !callbacks[event]) {
      for (const cb of anyCallbacks) {
        cb(event, ...args);
      }
    }
  }

  const on: Emitter['on'] = (event, cb) => {
    callbacks[event] = callbacks[event] || [];
    callbacks[event].push(cb);
  }

  const onAny: Emitter['onAny'] = cb => {
    anyCallbacks.push(cb);
  }

  const off: Emitter['off'] = (event, cb) => {
    if (callbacks[event]) {
      const idx = callbacks[event].indexOf(cb);
      callbacks[event].splice(idx, 1);
    }
  }

  const offAny: Emitter['offAny'] = cb => {
    anyCallbacks.splice(anyCallbacks.indexOf(cb), 1);
  }

  const reset: Emitter['reset'] = (event) => {
    if (!event) {
      for (const event of Object.keys(callbacks)) {
        delete callbacks[event];
      }
    } else {
      anyCallbacks.splice(0, anyCallbacks.length);
      delete callbacks[event];
    }
  }

  return { emit, on, onAny, off, offAny, reset }
}
