import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as api from './api'
import { ApiDataMutation, AppCtx, AppDispatch, AppState, BasicProfile, TableCtx, TableFilters } from './types'
import { tokens, webSocket } from '../api'
import { Data } from '../types'
import { mutatePath, pathOr } from '../util'

const AppStateCtx = createContext<AppCtx>(null);
let appDispatch: AppDispatch = null;
const ws = webSocket();

const initialState: AppState = {
  data: { ...api.initialData },
  status: 'init'
}

/** Error-catching reducer for async state update calls */
const apiDataReduce = async (
  nextFn: (...args: any) => Promise<ApiDataMutation>,
  ...args: any
): Promise<any> => {
  const [ state, setState ] = appDispatch;

  try {
    const next = await nextFn(...args);

    if (!next) {
      console.trace(`No next for args`, args);
    } else {
      const { force, path, type, mutation } = next;
      if (force || api.apiDataChanged(state.data, type, path, mutation)) {
        const nextData = mutatePath(path, mutation, state.data, type);
        setState({ data: nextData, status: 'idle' });
        return mutation;
      } else {
        setState({ data: state.data, status: 'idle' });
        return null;
      }
    }
  } catch(e) {
    switch(e) {
      case 'Network Error': {
        setState({ data: state.data, status: 'offline' });
        return null;
      }
      case 'invalid_persist': {
        tokens.del('persist');
        tokens.del('token');
        setState({ ...initialState, status: 'idle' });
        return null;
      }
      case 'permission': {
        if (confirm('Onverwacht probleem, klik op OK om de app te herladen')) {
          return window.location.reload();
        }
      }
      default: {
        setState({ data: state.data, status: 'idle' });
        throw e;
      }
    }
  }
}

export const AppProvider = (
  { children }: { children: JSX.Element | JSX.Element[] }
) => {
  appDispatch = useState<AppState>(initialState);
  const [ state, setState ] = appDispatch;
  const { i18n } = useTranslation();

  const profileHandler = (eventData: any) => apiDataReduce(
    api.wsReduce, appDispatch[0].data, 'PROFILE_EVENT', eventData
  );

  useEffect(() => {
    ws.disconnect();
    ws.off('PROFILE_EVENT', profileHandler);

    if (state.data.profile) {
      ws.connect();
      ws.on('TABLE_EVENT', eventData => apiDataReduce(
        api.wsReduce, appDispatch[0].data, 'TABLE_EVENT', eventData
      ));
      ws.on('PROFILE_EVENT', profileHandler)
    }
  }, [ state.data.profile?.type ]);

  useEffect(() => {
    const cached = localStorage.getItem('language');
    const lng = state.data.profile?.language || cached || 'en';
    i18n.changeLanguage(lng);
    if (cached !== lng) {
      localStorage.setItem('APP_LANGUAGE', lng);
    }
  }, [ state.data.profile?.language ]);

  const initialize: AppCtx['initialize'] = () => apiDataReduce(api.init);

  const login: AppCtx['login'] = async (loginData) => {
    setState({ ...state, status: 'loading' });
    return await apiDataReduce(api.login, loginData);
  }

  const logout: AppCtx['logout'] = async () => {
    setState({ ...state, status: 'loading' });
    return await apiDataReduce(api.logout);
  }

  const signupRecover: AppCtx['signupRecover'] = async (
    type, token, data
  ) => {
    setState({ ...state, status: 'loading' });
    return await apiDataReduce(api.signupRecover, type, token, data);
  }

  const tableCtx: AppCtx['table'] = (
    table: string,
    l10n: boolean = false,
    noFetch: boolean = false
  ) => {
    const data: Data[] = pathOr(['data', 'table', table], [], state);

    const record = (id: string) => id === 'new' ? { id: 'new' } : data.find((r => r.id === id));

    const setData: TableCtx['setData'] = (type, record) => {
      const recordIdx = state.data.table[table].findIndex(r => r.id === record.id);;
      const path: (string | number)[] = [ 'data', 'table', table ];
      if (recordIdx >= 0) {
        path.push(recordIdx);
        const nextState: AppState = mutatePath(path, record, { ...state }, type === 'delete' ? 'delete' : 'set');
        setState(nextState);
      } else {
        setState(mutatePath(path, record, state, 'insert'));
      }
    }

    const tableOperation = (
      type: 'insert' | 'update' | 'delete'
    ) => (record: Data) => {
      setState({ ...state, status: 'loading' });
      return apiDataReduce(api.operation, state.data, type, table, record);
    }

    const getTable = () => {
      setState({ ...state, status: 'loading' });
      const params = l10n && state.data?.profile?.language ? {
        language: state.data.profile.language
      } : undefined;

      return apiDataReduce(api.operation, state.data, 'get', table, params);
    }

    useEffect(() => {
      // Auto-refetch if table contains a language field
      if (
        (!state.data.table.hasOwnProperty(table) && !noFetch) ||
        (
          l10n && state.data.table.hasOwnProperty(table) &&
          state.data.table[table].length &&
          state.data.table[table][0].language !== state.data.profile?.language
        )
      ) {
        getTable();
      }
    }, [ state.data.profile?.language ]);

    return {
      data,
      record,
      setData,
      insert: tableOperation('insert'),
      update: tableOperation('update'),
      delete: tableOperation('delete')
    }
  }

  const wsOn: AppCtx['wsOn'] = (event, initialData: any = null) => {
    const [ data, setData ] = useState(initialData);

    useEffect(() => {
      ws.on(event, setData);
      return () => ws.off(event, setData);
    }, []);

    return data;
  }

  const appCtx: AppCtx = {
    data: state.data,
    enums: state.data.enums,
    status: state.status,
    profile: state.data.profile,

    initialize,
    login,
    logout,
    signupRecover,
    table: tableCtx,
    wsEmit: ws.emit,
    wsOn
  }

  useEffect(() => { initialize() }, []);

  return (
    <AppStateCtx.Provider value={appCtx}>{children}</AppStateCtx.Provider>
  );
}

type UseApp = <P = BasicProfile>() => AppCtx<P>
export const useApp: UseApp = () => useContext(AppStateCtx) as any;
