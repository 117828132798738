import React from 'react'

export const Logo = (props) => {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="140 108 224 288" xmlSpace="preserve" {...props}>
    <path fill="#0035AA" d="M348.408,296.421c19.14-64.35,13.733-145.916,13.733-145.916l-62.955-31.066c-49.07-24.124-94.021,0-94.021,0l-62.956,31.066c0,0-5.276,81.518,13.795,145.721h-15.795V332H170.7c0.035,0.063,0.068,0.13,0.104,0.193c1.294,2.236,2.588,4.473,4,6.59c0,0,28.948,40.01,76.253,56.484h1.177c47.305-16.475,77.43-56.484,77.43-56.484c1.412-2.117,2.706-4.354,4.001-6.59h30.595v-35.772H348.408z"/>
    <path fill="#008CFF" d="M251.646,381.264c-39.068-15.063-63.779-47.305-65.897-50.129c-15.297-23.535-25.417-61.779-29.183-110.731c-2.118-26.712-1.765-49.894-1.294-61.426l56.13-27.653l0.235-0.118c0.118-0.118,17.651-9.179,41.539-9.179c14.121,0,27.653,3.177,40.244,9.414l55.896,27.536c0.941,27.536,1.647,122.853-30.478,172.04C316.132,334.313,290.243,366.319,251.646,381.264z"/>
    <g fill="#001FFF" opacity='0.2'>
      <path d="M174.099,307.6c3.53,9.062,7.531,17.063,11.885,23.771c0.824,1.177,5.413,7.061,12.944,14.71l5.883,5.883V134.854l-30.713,15.18V307.6z"/>
      <path d="M236.937,123.793v250.999c4.707,2.472,9.649,4.825,14.827,6.825c5.648-2.118,10.943-4.707,16.004-7.414V123.558c-4.824-0.824-9.649-1.177-14.592-1.177C247.292,122.381,241.879,122.969,236.937,123.793z"/>
      <path d="M301.658,135.796v213.696c10.002-9.061,16.003-16.592,17.18-18.122c5.413-8.237,9.885-17.886,13.532-28.241V150.976L301.658,135.796z"/>
    </g>
    <rect x="146.445" y="303.129" fill="#FFFFFF" width="211.107" height="22.357"/>
    <g fill='#FFFFFF'>
      <path fill="#FFFFFF" d="M155.977,269.592l16.474-32.949h-20.593l5.531-10.944h54.954l-5.531,10.944h-20.593l-16.474,32.949H155.977z"/>
      <path fill="#FFFFFF" d="M196.104,269.592l22.005-43.893h13.768l-22.005,43.893H196.104z"/>
      <path fill="#FFFFFF" d="M215.638,269.592l13.768-27.418h54.836l-5.531,10.944h-41.186l-8.237,16.475H215.638z M232.112,236.643l5.531-10.944h54.835l-5.53,10.944H232.112z"/>
      <path fill="#FFFFFF" d="M276.24,269.592l22.005-43.893h54.954l-22.006,43.893h-13.768l8.237-16.475h-27.418l-8.237,16.475H276.24zM331.193,242.056l2.707-5.531h-27.418l-2.707,5.531H331.193z"/>
    </g>
    <g fill='#FFFFFF'>
      <path d="M220.933,146.151C220.815,146.151,220.815,146.151,220.933,146.151c-1.059,2.942-2,6.001-3.06,9.061c-3.06,0-6.001,0-9.061,0c0.118,0.118,0.235,0.118,0.353,0.235c1.177,0.941,2.354,1.765,3.413,2.707c1.177,0.941,2.354,1.765,3.53,2.707c0.118,0.118,0.235,0.235,0.118,0.471c-0.353,1.294-0.824,2.471-1.177,3.766c-0.471,1.647-1.059,3.295-1.53,4.825c0,0.118-0.118,0.353-0.118,0.471c2.589-2,5.06-3.883,7.531-5.766c2.471,1.883,5.06,3.883,7.531,5.766c0-0.118,0-0.118,0-0.118c-0.706-2.118-1.294-4.354-2-6.472c-0.235-0.824-0.588-1.647-0.824-2.589c0-0.118,0-0.235,0.118-0.353c0.235-0.235,0.588-0.471,0.824-0.706c0.941-0.706,1.883-1.53,2.942-2.236c0.824-0.588,1.647-1.294,2.471-1.883c0.353-0.235,0.706-0.588,1.177-0.941c-3.06,0-6.119,0-9.061,0C222.934,152.153,221.875,149.211,220.933,146.151z"/>
      <path d="M317.073,167.921L317.073,167.921c-1.06,2.942-2.001,6.001-3.06,9.061c-3.06,0-6.002,0-9.061,0c0.117,0.118,0.234,0.118,0.353,0.235c1.177,0.941,2.354,1.765,3.412,2.707c1.177,0.941,2.354,1.765,3.53,2.707c0.118,0.118,0.235,0.235,0.118,0.471c-0.354,1.294-0.824,2.471-1.177,3.765c-0.471,1.647-1.06,3.295-1.53,4.825c0,0.118-0.117,0.353-0.117,0.471c2.589-2,5.06-3.883,7.531-5.766c2.471,1.883,5.06,3.883,7.53,5.766c0-0.118,0-0.118,0-0.118c-0.705-2.118-1.294-4.354-2-6.472c-0.235-0.824-0.588-1.647-0.824-2.589c0-0.118,0-0.235,0.118-0.353c0.235-0.235,0.589-0.471,0.823-0.706c0.941-0.706,1.883-1.53,2.942-2.236c0.823-0.588,1.647-1.294,2.471-1.883c0.354-0.235,0.706-0.588,1.177-0.941c-3.06,0-6.119,0-9.061,0C319.073,173.922,318.132,170.863,317.073,167.921z"/>
      <path d="M284.947,146.151C284.83,146.151,284.83,146.151,284.947,146.151c-1.059,2.942-2,6.001-3.059,9.061c-3.061,0-6.002,0-9.062,0c0.118,0.118,0.235,0.118,0.354,0.235c1.177,0.941,2.353,1.765,3.412,2.707c1.177,0.941,2.354,1.765,3.53,2.707c0.117,0.118,0.235,0.235,0.117,0.471c-0.353,1.294-0.823,2.471-1.176,3.766c-0.471,1.647-1.06,3.295-1.53,4.825c0,0.118-0.118,0.353-0.118,0.471c2.59-2,5.061-3.883,7.531-5.766c2.472,1.883,5.061,3.883,7.531,5.766c0-0.118,0-0.118,0-0.118c-0.705-2.118-1.294-4.354-2-6.472c-0.235-0.824-0.588-1.647-0.824-2.589c0-0.118,0-0.235,0.118-0.353c0.235-0.235,0.589-0.471,0.823-0.706c0.941-0.706,1.883-1.53,2.942-2.236c0.823-0.588,1.647-1.294,2.472-1.883c0.353-0.235,0.705-0.588,1.176-0.941c-3.059,0-6.119,0-9.061,0C286.948,152.153,285.889,149.211,284.947,146.151z"/>
      <path d="M189.396,167.921L189.396,167.921c-1.059,2.942-2,6.001-3.06,9.061c-3.06,0-6.001,0-9.061,0c0.118,0.118,0.235,0.118,0.353,0.235c1.177,0.941,2.354,1.765,3.413,2.707c1.177,0.941,2.354,1.765,3.53,2.707c0.118,0.118,0.235,0.235,0.118,0.471c-0.353,1.294-0.824,2.471-1.177,3.765c-0.471,1.647-1.059,3.295-1.53,4.825c0,0.118-0.118,0.353-0.118,0.471c2.589-2,5.06-3.883,7.531-5.766c2.471,1.883,5.06,3.883,7.531,5.766c0-0.118,0-0.118,0-0.118c-0.706-2.118-1.294-4.354-2-6.472c-0.235-0.824-0.588-1.647-0.824-2.589c0-0.118,0-0.235,0.118-0.353c0.235-0.235,0.588-0.471,0.824-0.706c0.941-0.706,1.883-1.53,2.942-2.236c0.824-0.588,1.647-1.294,2.471-1.883c0.353-0.235,0.706-0.588,1.177-0.941c-3.06,0-6.119,0-9.061,0C191.397,173.922,190.456,170.863,189.396,167.921z"/>
    </g>
    <g fill='#0035AA'>
      <path d="M193.515,310.424l-2.824,7.768c-1.412,0.353-2.942,0.588-4.707,0.588c-1.647,0-3.295-0.235-4.589-0.588l2.824-7.768c1.412-0.353,3.06-0.588,4.707-0.588C190.691,309.836,192.338,310.071,193.515,310.424z M185.042,316.661h3.177l1.765-4.825h-3.177L185.042,316.661z"/>
      <path d="M204.106,312.189l-2.354,6.473h-3.06l2.354-6.473h-3.06l0.824-2.118h9.296l-0.824,2.118H204.106z"/>
      <path d="M221.875,310.071c0,0.823-0.118,1.53-0.353,2.235c-0.353,0.824-0.706,1.53-1.294,2.119c0.118,0.588,0,1.412-0.235,2.117c-0.353,0.824-0.706,1.648-1.177,2.236h-8.943l3.06-8.59h8.943V310.071z M217.521,315.249h-3.295l-0.588,1.647h3.295c0.118-0.235,0.353-0.471,0.353-0.824C217.403,315.72,217.521,315.367,217.521,315.249z M218.815,311.836h-3.295l-0.588,1.648h3.295c0.235-0.236,0.353-0.471,0.471-0.824C218.697,312.307,218.697,312.072,218.815,311.836z"/>
      <path d="M233.76,318.544h-2.824l0.118-1.647h-3.06l-1.059,1.647h-3.177l5.884-8.591h4.354L233.76,318.544z M231.524,311.602l-2.236,3.412h2L231.524,311.602z"/>
      <path d="M247.057,316.426l-0.824,2.118h-7.766l3.059-8.591h3.06l-2.354,6.473H247.057z"/>
      <path d="M259.883,316.426l-0.823,2.118h-7.767l3.06-8.591h3.06l-2.354,6.473H259.883z"/>
      <path d="M280.006,312.072h-2.824l-0.354,1.059l5.178,1.059l-1.412,3.884c-1.295,0.353-2.707,0.589-4.354,0.589c-1.647,0-3.06-0.118-4.119-0.589l0.824-2.354h2.824l-0.354,0.941h2.707l0.353-1.06l-5.178-1.059l1.412-3.883c1.294-0.354,2.706-0.589,4.354-0.589c1.646,0,3.059,0.118,4,0.589l-0.823,2.354h-2.824L280.006,312.072z"/>
      <path d="M297.422,310.542l-0.941,2.706h-2.942l0.471-1.176h-2.942l-1.646,4.589h2.941l0.471-1.177h2.941l-0.941,2.707c-1.294,0.353-2.824,0.588-4.471,0.588c-1.648,0-3.178-0.235-4.354-0.588l2.706-7.414c1.412-0.354,2.942-0.588,4.59-0.588C294.715,310.071,296.244,310.189,297.422,310.542z"/>
      <path d="M305.658,318.426l1.06-3.059h-2.942l-1.059,3.059h-2.941l2.941-8.236h2.941l-1.059,2.941h2.941l1.06-2.941h2.941l-2.941,8.236H305.658z"/>
      <path d="M325.545,310.542l-2.706,7.413c-1.294,0.354-2.824,0.589-4.472,0.589s-3.177-0.235-4.354-0.589l2.706-7.413c1.412-0.353,2.942-0.589,4.59-0.589C322.839,310.071,324.369,310.189,325.545,310.542z M317.426,316.661h2.942l1.647-4.589h-2.942L317.426,316.661z"/>
      <path d="M339.549,310.542l-2.707,7.413c-1.294,0.354-2.824,0.589-4.472,0.589s-3.177-0.235-4.354-0.589l2.706-7.413c1.412-0.353,2.942-0.589,4.59-0.589C336.96,310.071,338.372,310.189,339.549,310.542z M331.429,316.661h2.942l1.647-4.589h-2.942L331.429,316.661z"/>
      <path d="M350.14,316.309l-0.706,2h-7.414l2.942-8.237h2.941l-2.235,6.119h4.472V316.309z"/>
      <path d="M159.39,312.072l-0.471,1.176h3.766l-0.706,2.001h-3.648l-1.177,3.413h-3.06l3.06-8.591h7.767l-0.706,2.118h-4.825V312.072z"/>
      <path d="M179.276,310.424l-2.824,7.768c-1.412,0.353-2.942,0.588-4.707,0.588c-1.647,0-3.294-0.235-4.589-0.588l2.824-7.768c1.412-0.353,3.059-0.588,4.707-0.588C176.57,309.836,178.1,310.071,179.276,310.424z M170.804,316.661h3.177l1.765-4.825h-3.177L170.804,316.661z"/>
    </g>
  </svg>;
}

export const TextLogo = ({ color, ...props }: {[key: string]: any}) => {
  const fill = color || 'var(--text-light)'

  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="102 37 173 103" xmlSpace="preserve" {...props}>
    <g fill={fill}>
      <path d="M138.1,56.2c0.9,2.6,1.7,5.1,2.6,7.7c2.6,0,5.1,0,7.7,0c-0.3,0.3-0.7,0.5-1,0.8c-0.7,0.5-1.4,1.1-2.1,1.6c-0.8,0.6-1.6,1.3-2.5,1.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.7,0.5,1.4,0.7,2.2c0.6,1.8,1.1,3.7,1.7,5.5c0,0,0,0.1,0,0.1c-2.2-1.7-4.3-3.3-6.4-4.9c-2.1,1.6-4.3,3.3-6.4,4.9c0-0.2,0.1-0.3,0.1-0.4c0.4-1.4,0.9-2.8,1.3-4.1c0.3-1.1,0.7-2.1,1-3.2c0.1-0.2,0-0.3-0.1-0.4c-1-0.7-2-1.5-3-2.3c-1-0.8-2-1.5-2.9-2.3c-0.1-0.1-0.2-0.1-0.3-0.2c2.6,0,5.1,0,7.7,0C136.3,61.3,137.2,58.7,138.1,56.2C138,56.2,138.1,56.2,138.1,56.2z"/>
      <path d="M164.8,37.8c0.9,2.6,1.7,5.1,2.6,7.7c2.6,0,5.1,0,7.7,0c-0.3,0.3-0.7,0.5-1,0.8c-0.7,0.5-1.4,1.1-2.1,1.6c-0.8,0.6-1.6,1.3-2.5,1.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.7,0.5,1.4,0.7,2.2c0.6,1.8,1.1,3.7,1.7,5.5c0,0,0,0.1,0,0.1c-2.2-1.7-4.3-3.3-6.4-4.9c-2.1,1.6-4.3,3.3-6.4,4.9c0-0.2,0.1-0.3,0.1-0.4c0.4-1.4,0.9-2.8,1.3-4.1c0.3-1.1,0.7-2.1,1-3.2c0.1-0.2,0-0.3-0.1-0.4c-1-0.7-2-1.5-3-2.3c-1-0.8-2-1.5-2.9-2.3c-0.1-0.1-0.2-0.1-0.3-0.2c2.6,0,5.1,0,7.7,0C163.1,42.9,163.9,40.3,164.8,37.8C164.8,37.8,164.8,37.8,164.8,37.8z"/>
      <path d="M219.2,37.8c0.9,2.6,1.7,5.1,2.6,7.7c2.6,0,5.1,0,7.7,0c-0.3,0.3-0.7,0.5-1,0.8c-0.7,0.5-1.4,1.1-2.1,1.6c-0.8,0.6-1.6,1.3-2.5,1.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.7,0.5,1.4,0.7,2.2c0.6,1.8,1.1,3.7,1.7,5.5c0,0,0,0.1,0,0.1c-2.2-1.7-4.3-3.3-6.4-4.9c-2.1,1.6-4.3,3.3-6.4,4.9c0-0.2,0.1-0.3,0.1-0.4c0.4-1.4,0.9-2.8,1.3-4.1c0.3-1.1,0.7-2.1,1-3.2c0.1-0.2,0-0.3-0.1-0.4c-1-0.7-2-1.5-3-2.3c-1-0.8-2-1.5-2.9-2.3c-0.1-0.1-0.2-0.1-0.3-0.2c2.6,0,5.1,0,7.7,0C217.5,42.9,218.3,40.3,219.2,37.8C219.2,37.8,219.2,37.8,219.2,37.8z"/>
      <path d="M246.6,56.2c0.9,2.6,1.7,5.1,2.6,7.7c2.6,0,5.1,0,7.7,0c-0.3,0.3-0.7,0.5-1,0.8c-0.7,0.5-1.4,1.1-2.1,1.6c-0.8,0.6-1.6,1.3-2.5,1.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.7,0.5,1.4,0.7,2.2c0.6,1.8,1.1,3.7,1.7,5.5c0,0,0,0.1,0,0.1c-2.2-1.7-4.3-3.3-6.4-4.9c-2.1,1.6-4.3,3.3-6.4,4.9c0-0.2,0.1-0.3,0.1-0.4c0.4-1.4,0.9-2.8,1.3-4.1c0.3-1.1,0.7-2.1,1-3.2c0.1-0.2,0-0.3-0.1-0.4c-1-0.7-2-1.5-3-2.3c-1-0.8-2-1.5-2.9-2.3c-0.1-0.1-0.2-0.1-0.3-0.2c2.6,0,5.1,0,7.7,0C244.8,61.3,245.7,58.8,246.6,56.2C246.5,56.2,246.6,56.2,246.6,56.2z"/>
    </g>
    <g fill={fill}>
      <path d="M106.7,123.5l14-28h-17.5l4.7-9.3h46.7l-4.7,9.3h-17.5l-14,28H106.7z"/>
      <path d="M140.7,123.5l18.7-37.3h11.7l-18.7,37.3H140.7z"/>
      <path d="M157.3,123.5l11.7-23.3h46.7l-4.7,9.3h-35l-7,14H157.3z M171.3,95.5l4.7-9.3h46.7l-4.7,9.3H171.3z"/>
      <path d="M208.9,123.5l18.7-37.3h46.7l-18.7,37.3h-11.7l7-14h-23.3l-7,14H208.9z M255.5,100.1l2.3-4.7h-23.3l-2.3,4.7H255.5z"/>
    </g>
    <g fill={fill}>
      <path d="M132.9,133.3l-2.2,6c-1.1,0.3-2.3,0.4-3.6,0.4c-1.3,0-2.5-0.2-3.5-0.4l2.2-6c1.1-0.3,2.4-0.4,3.7-0.4C130.8,132.8,132,133,132.9,133.3z M126.4,138.1h2.4l1.3-3.7h-2.4L126.4,138.1z"/>
      <path d="M141.1,134.6l-1.8,4.9h-2.4l1.8-4.9h-2.4l0.6-1.7h7.1l-0.6,1.7H141.1z"/>
      <path d="M154.8,133c0,0.6-0.1,1.2-0.2,1.7c-0.2,0.6-0.5,1.2-1,1.6c0.1,0.5,0,1.1-0.2,1.6c-0.2,0.6-0.6,1.3-0.9,1.7h-6.9l2.4-6.6H154.8z M151.4,137h-2.5l-0.5,1.3h2.5c0.1-0.2,0.2-0.4,0.3-0.6C151.3,137.4,151.4,137.1,151.4,137z M152.4,134.3h-2.6l-0.5,1.3h2.5c0.1-0.2,0.3-0.4,0.3-0.6C152.3,134.7,152.3,134.5,152.4,134.3z"/>
      <path d="M163.9,139.6h-2.2l0.1-1.2h-2.4l-0.8,1.2h-2.4l4.5-6.6h3.4L163.9,139.6z M162.2,134.2l-1.7,2.6h1.5L162.2,134.2z"/>
      <path d="M174.1,137.9l-0.6,1.7h-6l2.4-6.6h2.4l-1.8,5H174.1z"/>
      <path d="M184,137.9l-0.6,1.7h-6l2.4-6.6h2.4l-1.8,5H184z"/>
      <path d="M199.5,134.5h-2.1l-0.3,0.8l4,0.8l-1.1,3c-1,0.3-2.1,0.4-3.3,0.4c-1.3,0-2.4-0.1-3.1-0.4l0.7-1.8h2.2l-0.2,0.7h2.1l0.3-0.8l-4-0.8l1.1-3c1-0.3,2.1-0.4,3.3-0.4c1.3,0,2.4,0.1,3.1,0.4l-0.6,1.8h-2.2L199.5,134.5z"/>
      <path d="M212.9,133.4l-0.7,2.1h-2.3l0.3-0.9H208l-1.3,3.5h2.3l0.3-0.9h2.3l-0.7,2.1c-1,0.3-2.2,0.4-3.5,0.4c-1.3,0-2.4-0.1-3.3-0.4l2.1-5.7c1.1-0.3,2.3-0.4,3.5-0.4C210.9,133,212,133.1,212.9,133.4z"/>
      <path d="M219.3,139.4l0.9-2.4h-2.3l-0.9,2.4h-2.3l2.3-6.3h2.3l-0.8,2.3h2.3l0.8-2.3h2.3l-2.3,6.3H219.3z"/>
      <path d="M234.6,133.4l-2.1,5.7c-1,0.3-2.2,0.4-3.5,0.4c-1.3,0-2.4-0.1-3.3-0.4l2.1-5.7c1.1-0.3,2.3-0.4,3.5-0.4C232.6,133,233.7,133.1,234.6,133.4z M228.3,138h2.3l1.3-3.5h-2.3L228.3,138z"/>
      <path d="M245.4,133.4l-2.1,5.7c-1,0.3-2.2,0.4-3.5,0.4c-1.3,0-2.4-0.1-3.3-0.4l2.1-5.7c1.1-0.3,2.3-0.4,3.5-0.4C243.4,133,244.5,133.1,245.4,133.4z M239.2,138h2.3l1.3-3.5h-2.3L239.2,138z"/>
      <path d="M253.5,137.9l-0.6,1.6h-5.7l2.3-6.3h2.3l-1.7,4.7H253.5z"/>
      <path d="M106.7,134.6l-0.3,0.9h2.9l-0.5,1.5h-2.9l-0.9,2.6h-2.4l2.4-6.6h6l-0.6,1.6H106.7z"/>
      <path d="M122,133.3l-2.2,6c-1.1,0.3-2.3,0.4-3.6,0.4c-1.3,0-2.5-0.2-3.5-0.4l2.2-6c1.1-0.3,2.4-0.4,3.7-0.4C119.8,132.8,121,133,122,133.3z M115.4,138.1h2.4l1.3-3.7h-2.4L115.4,138.1z"/>
    </g>
  </svg>
}
