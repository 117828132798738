import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import React, { useMemo, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'regenerator-runtime/runtime'

dayjs.extend(isoWeek);
dayjs.extend(utc);

import { Application, Login, Recovery } from './application'
import { PrivacyPolicy } from './application/privacyPolicy'
import { SplashScreen } from './application/splash'
import { i18nInit } from './i18n'
import { useApp, AppProvider } from './lib/state'
import { apiRequest } from './lib/api'
import { Profile } from './types'

import './components/components.css'

i18nInit();

const Main = () => {
  const app = useApp<Profile>();
  const { status, profile } = app;

  // @ts-ignore
  window.app = app;

  const [ splash, setSplash ] = useState<boolean>(null);
  const clearSplash = () => setSplash(false);

  useMemo(() => {
    if (profile && splash === null) {
      setSplash(profile.type === 'api_user' ? true : false);
    } else if (!profile && splash === false) {
      setSplash(null);
    }
  }, [ profile ]);

  // console.log(`app state - ${status} (${splash ? 'splash' : 'nosplash'})`);
  if (window.location.pathname === '/privacy_policy_nl') {
    return <PrivacyPolicy lang='nl' />
  }
  if (window.location.pathname === '/privacy_policy') {
    return <PrivacyPolicy lang='en' />
  }

  if (status === 'offline') {
    return <div>The server is offline, try again later</div>
  } else if (profile && splash) {
    return <SplashScreen clear={clearSplash} profile={profile} />
  } else if (
    window.location.pathname.indexOf('recover') >= 0 &&
    window.location.pathname.replace('/recover/','').length > 0
  ) {
    return <Recovery />
  } else if (!profile && status!== 'init') {
    return <Login />;
  } else if (profile && splash !== null) {
    return splash
      ? <SplashScreen clear={clearSplash} profile={profile} />
      : <Application />;
  } else {
    return <div />
  }
}

const container = document.getElementById('app');
const root = createRoot(container);

root.render(<AppProvider>
  <Main />
  <ToastContainer
  position='bottom-center'
  autoClose={2000}
  hideProgressBar={true}
/></AppProvider>);

if ('serviceWorker' in navigator) {
  const channel = new BroadcastChannel('sw');
  channel.onmessage = async e => {
    // console.log(`[app] sw event`, e?.data);
    const [ event, data ] = e?.data || [];

    if (event === 'APP_UPDATE') {
      const current = localStorage.getItem('APP_VERSION');

      if (current !== data) {
        try {
          await apiRequest('put', '/api/stats/version', {
            version: data, version_since: dayjs().toISOString()
          });
        } catch(e) {};

        alert(`App update: ${current ? `${current} -> `: ''}${data}`);

        localStorage.setItem('APP_VERSION', data);
        window.location.reload();
      }
    }
  }

  navigator.serviceWorker.register(
    new URL('./sw.js', import.meta.url),
    { type: 'module' }
  ).catch(e => {
    console.log(`SW error`, e)
  });
}
