import axios, { AxiosRequestConfig } from 'axios'

import { pathOr } from './util'
import { baseURL, wsURL } from '../config'
import { emitter } from './emitter'

export type TokenType = 'token' | 'persist'

let ws: WebSocket = null;
let wsEmitter = emitter();
let initialized = false;

const tokenOp = (
  operation: 'get' | 'set' | 'del',
  type: TokenType,
  data?: string
) => {
  const storage = (type === 'token') ? sessionStorage : localStorage;
  switch(operation) {
    case 'set': storage.setItem(type, data); return data;
    case 'del': storage.removeItem(type); return null;
    default: return storage.getItem(type);
  }
}

export const tokens = {
  get: (type: TokenType) => tokenOp('get', type),
  set: (type: TokenType, data: string) => tokenOp('set', type, data),
  del: (type: TokenType) => tokenOp('del', type)
}

const doRequest = async (
  method: AxiosRequestConfig['method'],
  route: string,
  data: any = null,
  extraOpts: AxiosRequestConfig = {},
  tokenType: TokenType = 'token'
) => {
  const token = tokens.get(tokenType);

  const headers = extraOpts.headers || {};
  if (tokenType !== 'token' && token && !headers.authorization) {
    headers.authorization = `Bearer ${token}`;
  }

  const opts: AxiosRequestConfig = {
    baseURL,
    headers,
    method,
    url: route,
    data,
    ...extraOpts,
  };

  try {
    const { data } = await axios.request(opts);
    return data;
  } catch(e) {
    throw pathOr(['response', 'data', 'error'], e.message)(e);
  }
}

export const apiRequest = async (
  method: AxiosRequestConfig['method'],
  route: string,
  data: any = null,
  extraOpts: AxiosRequestConfig = {},
  tokenType: TokenType = 'token'
) => {
  try {
    return await doRequest(method, route, data, extraOpts, tokenType);
  } catch(e) {
    const persist = tokens.get('persist');
    if (e === 'no_jwt' && persist && tokenType === 'token') {
      const { token: newToken } = await doRequest(
        'put', '/api/account/refresh', null, {}, 'persist' 
      );
      tokens.set('token', newToken);
      // @TODO maybe add action()?
      return await doRequest(method, route, data, extraOpts, tokenType);
    } else {
      throw e;
    }
  }
}

window.apiRequest = apiRequest;

export const webSocket = () => {
  const onClose = () => {
    console.log('ws disconnected, reconnecting...');
    setTimeout(connect, 5000);
  };

  const onError = async e => {
    const token = tokens.get('token');
    if (!token) {
      initialized = false;
    }
    if (!initialized) {
      try {
        const data = await apiRequest('get', '/api/account');
        if (data?.token) {
          tokens.set('token', data.token);
        }
      } catch(e) {
        console.log(`ws error`, e);
      }
    }
  }

  const onMessage = (e: MessageEvent) => {
    const { event, data } = JSON.parse(e.data);
    wsEmitter.emit(event, data);
  }

  const onOpen = () => {
    console.log('ws connected');
    initialized = true;
  }

  const disconnect = () => {
    initialized = false;
    if (ws !== null) {
      ws.removeEventListener('close', onClose);
      ws.removeEventListener('error', onError);
      ws.removeEventListener('message', onMessage);
      ws.removeEventListener('open', onOpen);
      ws.close();
      ws = null;
      wsEmitter.reset();
    }
  }

  const connect = (url: string = wsURL) => {
    const token = tokens.get('token');
    ws = new WebSocket(url, token);

    ws.addEventListener('close', onClose);
    ws.addEventListener('error', onError);
    ws.addEventListener('message', onMessage);
    ws.addEventListener('open', onOpen);
  }

  const wsEmit = (event: string, data: any) => {
    if (ws === null) {
      return false;
    }
    ws.send(JSON.stringify({ event, data }));
    return true;
  }

  return {
    connect,
    disconnect,
    on: wsEmitter.on,
    onAny: wsEmitter.onAny,
    off: wsEmitter.off,
    emit: wsEmit
  }
}
