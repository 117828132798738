import { Dayjs } from 'dayjs';

import { Data } from './types'

export const dayLetters = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
export const days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
export const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];

export const dateString = (t: Dayjs) => `${days[t.day()]}, ${t.date()} ${months[t.month()]} ${t.year()}`
const tstr = (nr: number) => nr.toString().padStart(2, '0');

export const emitSw = (data: Record<string,any>) => {
  if (
    'serviceWorker' in navigator &&
    navigator.serviceWorker?.controller?.state === 'activated'
  ) {
    navigator.serviceWorker.controller.postMessage(data);
  }
}

export const sToHms = (seconds: number) => {
  if (!seconds || isNaN(seconds)) {
    return `00:00`;
  }
  const next = ({ h, m, s }) =>
    (s <= 60 && m <= 60) ? { h, m, s } :
    (m > 60) ? next({ h: h + 1, m: m - 60, s }) :
    next({ h, m: m + 1, s: s - 60 });

  const { h, m, s } = next({ h: 0, m: 0, s: Math.round(seconds) });
  return `${h?`${tstr(h)}:`:''}${tstr(m)}:${tstr(s)}`;
}

export const isNil = (val: any) => (val === null || val === undefined) ? true : false;

export const isEqual = (a: any, b: any) => {
  if (a === null) {
    return b === null;
  }
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
  } else if (
    typeof a === 'object' && typeof b === 'object' &&
    Object.keys(a).length === Object.keys(b).length
  ) {
    Object.entries(a).map(([ k, v ]) => {
      if ((v !== b[k]) || (typeof v === 'object' && !isEqual(v, b[k]))) {
        return false;
      }
    });
  } else {
    return (a === b);
  }
  return true;
}

export const pathOr = (path: (string | number)[], or: any, o?: Object): any => {
  if (o === undefined) {
    return (obj: Object) => pathOr(path, or, obj);
  }

  if (typeof o !== 'object' || o[path[0]] === undefined) {
    return or;
  }
  if (path.length === 1) {
    return o[path[0]];
  }
  const [ key, ...rest ] = path;
  return pathOr(rest, or)(o[key]);
}

export const clone = (o: any) => {
  if (typeof o === 'object' && o !== null) {
    if (Array.isArray(o)) {
      return o.map(clone);
    } else {
      const c = {};
      for (const k of Object.keys(o)) {
        c[k] = clone(o[k]);
      }
      return c;
    }
  } else {
    return o;
  }
}

export const mutatePath = (
  path: (string | number)[], mutation: any, obj: any,
  type: 'set' | 'insert' | 'update' | 'delete' = 'set'
) => {
  const setNode = (remaining: (string | number)[], node: any) => {
    if (remaining.length === 0) {
      obj = mutation;
    } else {
      const [ key, ...rest ] = remaining;
      if (rest.length === 0) {
        if (type === 'delete') {
          (node as any[]).splice(key as number, 1);
        } else if (type === 'insert') {
          (node[key] as any[]).push(mutation);
        } else if (type === 'update') {
          node[key] = { ...node[key], ...mutation };
        } else {  // type = 'set'
          node[key] = mutation;
        }
      } else {
        node[key] = node[key] ? clone(node[key]) : typeof rest[0] === 'number' ? [] : {};
        setNode(rest, node[key]);
      }
    }
  }
  setNode(path, obj);
  return obj;
}

export const pick = (keys: string[], o?: Data): any => {
  if (o === undefined) {
    return (obj: Data) => pick(keys, obj);
  }

  const picked: Data = {};
  for (const key of keys) {
    picked[key] = o[key];
  }
  return picked;
}

export const setTheme = (theme: {[key: string]: any}) => {
  if (!theme) {
    try {
      const savedTheme = JSON.parse(localStorage.getItem('CURRENT_THEME'));
      if (savedTheme) {
        setTheme(savedTheme);
      }
    } catch(e) {}
  } else {
    for (const [ k, v ] of Object.entries(theme)) {
      document.documentElement.style.setProperty(`--${k}`, v);
    }
  }
}

export const b64toU8a = (base64String: string) => {
  const rawData = window.atob((
    base64String + '='.repeat((4 - base64String.length % 4) % 4)
  ).replace(/-/g, '+').replace(/_/g, '/'));
  return (new Uint8Array(rawData.length)).map((v,i) => rawData.charCodeAt(i));
}

export const action = (type: string, data: Record<string,any> = {}) => {
  if ('ReactNativeWebView' in window) {
    (window as any).ReactNativeWebView.postMessage(JSON.stringify({
      ...data,
      type
    }));

    return;
  }

  console.log(`Action [${type}]`, data);
}
