import React, { useEffect, useState } from 'react'
import { Logo } from '../components/logo'

import characters from '../static/img/characters'
import { Profile } from '../types'

import './splash.css'

interface SplashScreenProps {
  clear: () => void
  profile: Profile
}

export const SplashScreen = (
  { clear, profile }: SplashScreenProps
) => {
  const fontSize = Math.round(100 / (profile.name.length + 1) * 1.15);
  const [ exit, setExit ] = useState(false);

  const doExit = () => {
    setExit(true);
    setTimeout(() => {
      clear();
    }, 1500);
  }

  useEffect(() => {
    setTimeout(() => {
      doExit();
    }, 2000);
  }, []);

  return <div className='screen splash' onClick={doExit}>
    <Logo width='50px' className='splash-logo' />
    <div id='splash-welcome' className={`text lg color-light${exit ? ' exit' :''}`}>WELCOME</div>
    <div id='splash-name' className={`text color-light${exit ? ' exit' :''}`} style={{
      lineHeight: '0.9em',
      fontSize: `${fontSize}vw`
    }}>{profile.name.toUpperCase()}</div>
    <img id='splash-character' className={exit ? 'exit' : ''} src={characters[profile.group_key]} alt={profile.group_key} />
  </div> 
}
