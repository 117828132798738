export default {
  common: {
    credits: 'Tegoed',
    next_training: 'Volgende training',
    actions: {
      cancel: 'ANNULEER',
      delete: 'VERWIJDER',
      edit: 'BEWERK',
      save: 'SLA OP',
      send: 'VERSTUUR',
      prompt: 'Weet je het zeker?'
    },
    errors: {
      save_failed: 'OPSLAAN MISLUKT'
    },
    sub_end_warning_day_one: 'Het abonnement verloopt over {{count}} dag.',
    sub_end_warning_day_other: 'Het abonnement verloopt over {{count}} dagen.',
    sub_end_warning_week: 'Het abonnement verloopt over {{count}} weken.',
    sub_end_warning_message: ' Neem contact op met TIFA Sports om te verlengen en door te ontwikkelen!'
  },

  login: {
    change_pass: 'Verander wachtwoord',
    confirm_change_pass: 'Als het ingevulde email-adres bekend is, heb je een recovery-mail ontvangen',
    forgot_pass: 'Wachtwoord vergeten?',
    go_back: 'Terug naar inloggen',
    persist: 'Blijf ingelogd',
  },

  user: {
    fields: {
      name: 'VOORNAAM',
      last_name: 'ACHTERNAAM',
      birth_date: 'GEBOORTEDATUM',
      phone_nr: 'TELEFOONNUMMER',
      team: 'CLUB / TEAM',
      language: {
        _label: 'TAAL',
        en: 'English',
        nl: 'Nederlands'
      },
      avatar: 'PROFIELFOTO',
    },
    change_pass: 'VERANDER WACHTWOORD',
  },
  
  slot: {
    label: 'BOEKINGEN',

    actions: {
      book: 'RESERVEER',
      cancel: 'CANCEL BOEKING'
    },

    available: 'TIJD SLOTS',
    booked: 'BOEKINGEN',
    no_refund: 'Je krijg je credit niet vergoed'
  },

  video: {

  },

  rules: {

  },

  feedback: {
    fields: {
      type: {
        _label: '',
        _empty: 'Kies een categorie...',
        Bug: 'Bug',
        Design: 'Design',
        Functionaliteit: 'Functionaliteit',
        Overig: 'Overig'
      }
    },
    descriptions: {
      Bug: 'Heb je een probleem ontdekt in de app? Laat ons graag zo duidelijk mogelijk weten wat je precies deed, wat er gebeurde, en wat je had verwacht dat er zou gebeuren',
      Design: 'Heb je tips voor het ontwerp? Laat ons weten wat er beter kan!',
      Functionaliteit: 'Mis je iets, of heb je tips voor hoe een bepaalde functionaliteit zou moeten werken? We horen het graag!',
      Overig: 'Heb je algemene tips of suggesties? Laat het ons weten!'
    },

    type_choice: 'Kies het soort feedback dat je wilt geven:',
    reload_description: 'Nieuwe feature niet beschikbaar?',
    reload_text: 'HERLAAD DE APP',
    thanks_text: 'Bedankt voor je feedback!',
    error_text: 'Er is iets foutgegaan bij het versuren van je feedback; laat het ons weten via info@tifasports.com'
  },

  categories: {
    label: 'Categorieën',
    hero_text: {
      flash: 'Een speler die bliksemsnel bij de Hulk-categorie wilt komen',
      batman: 'Peutervoetbal, 4 tot 6 jaar oud',
      hulk: 'Een speler die onder eerste divisie niveau speelt, amateur',
      black_panther: 'Een speler die op eerste divisie niveau speelt, zowel BVO als hoog-amateur',
      wolverine: 'Een speler die op Eredivisieniveau speelt, BVO',
      spiderman: 'Een topspeler die speelt bij Feyenoord, Ajax, PSV, AZ, BVO',
      iron_man: 'Een speler die international is, BVO'
    }
  },

  about: {
    label: 'Over ons...'
  }
}
