import { equals } from 'ramda'

import { ApiData, ApiDataMutation } from './types';
import { apiRequest, tokens } from '../api';
import { Data } from '../types';
import { action, pathOr } from '../util';
import * as cfg from '../../config';

export const initialData: ApiData = {
  enums: {}, profile: null, table: {}, ws: {}
}

export const apiDataChanged = (
  data: ApiData, type: string, path: (string | number)[], mutation: Data
) => {
  const stateData = pathOr(path, undefined, data) as any[];

  if (type === 'insert') {
    const idx = stateData.findIndex(r=>r.id === mutation.id);
    return idx < 0;
  } else if (type === 'delete') {
    return Boolean(stateData);
  } else {
    return !equals(stateData, mutation);
  }
}

export const init = async (): Promise<ApiDataMutation> => {
  const token = tokens.get('token');
  const persist = tokens.get('persist');
  const { initAlways } = cfg as Data;

  if (token || persist || initAlways) {
    try {
      const initData = await apiRequest('get', '/api/init', null, {
        headers: { authorization: `Bearer ${token}` }
      });

      if (initData?.profile) {
        action('profile', initData.profile);
      }

      const data: ApiDataMutation = {
        mutation: initData,
        path: [], type: 'set'
      };

      return data;
    } catch(e) {
      if (e !== 'permission') {
        console.log(`Unknown error`, e);
      }
    }
  }

  // emitSw({ type: 'set', token: null, src: 'INIT' });
  return { path: [], mutation: { ...initialData }, type: 'set', force: true };
}

export const login = async (loginData: Data): Promise<ApiDataMutation> => {
  const { token, persist } = await apiRequest('put', '/api/account/login', loginData);
  if (token) {
    tokens.set('token', token)
  }

  if (persist) {
    tokens.set('persist', persist);
  }

  return await init();
}

export const logout = async (): Promise<ApiDataMutation> => {
  try {
    await apiRequest('put', '/api/account/auth/logout');
  } finally {
    tokens.del('persist');
    tokens.del('token');
    // emitSw({ type: 'set', token: null, src: 'LOGOUT' });
    return { path: [], mutation: { ...initialData }, type: 'set' };
  }
}

export const signupRecover = async (
  type: 'signup' | 'recover', token: string, data: Data
) => {
  const { token: newToken, persist } = await apiRequest('post', `/api/account/${type}`, data, {
    headers: { authorization: `Bearer ${token}` }
  });
  tokens.set('token', newToken);
  if (persist) {
    tokens.set('persist', persist);
  }
  return await init();
}

export const operation = async (
  data: ApiData,
  type: 'one' | 'get' | 'insert' | 'update' | 'delete',
  table: string,
  operationData?: string | Data
): Promise<ApiDataMutation> => {
  const tableRoute = `/api/data/${table}`;

  switch(type) {
    case 'one': {
      const mutation = await apiRequest('get', `${tableRoute}/${operationData}`);
      return { path: ['table', table], mutation, type: 'insert' };
    }
    case 'get': {
      const mutation = await apiRequest(
        'get', `${tableRoute}`, undefined, { params: operationData }
      );
      return { path: ['table', table], mutation, type: 'set' };
    }
    case 'insert': {
      const mutation = await apiRequest('post', `${tableRoute}`, operationData);
      const idx = data.table[table].findIndex(r => r.id === mutation.id);
      return idx < 0 ? { path: ['table', table], mutation, type: 'insert' }
        : { path: ['table', table, idx], mutation, type: 'set' };
    }
    case 'update': {
      const mutation = await apiRequest('put', `${tableRoute}`, operationData);
      const idx = data.table[table].findIndex(r => r.id === mutation.id);
      return { path: ['table', table, idx], mutation, type: 'update' };
    }
    case 'delete': {
      const mutation = await apiRequest('delete', tableRoute, operationData);
      const idx = data.table[table].findIndex(r => r.id === mutation.id);
      return { path: ['table', table, idx], mutation, type: 'delete' };
    }
    default:
      alert(`invalid api operation ${type}`);
  }
  return null;
}

export const wsReduce = async (
  data: ApiData, event: string, eventData: any
): Promise<ApiDataMutation> => {
  switch(event) {
    case 'TABLE_EVENT': {
      const { type, table, record } = eventData;

      // Hasn't been fetched yet - skip since next XHR will fetch this change
      if (!data.table[table]) return null;

      const idx = data.table[table].findIndex(r => r.id === record?.id);
      switch(type) {
        case 'insert': case 'update': {
          if (idx !== null && idx >= 0) {
            return { path: ['table', table, idx], type: 'update', mutation: record };
          } else if (Array.isArray(record) && type === 'insert') {
            // Specific implementation for array appends; should be more generic
            return { path: ['table', table], type: 'set', mutation: data.table[table].concat(record) };
          } else {
            return { path: ['table', table], type: 'insert', mutation: record };
          }
        }
        case 'delete': {
          if (Array.isArray(record)) {
            const remaining = data.table[table].filter(r => !Boolean(record.find(rec => rec.id === r.id)));
            return { path: ['table', table], type: 'set', mutation: remaining };
          } else {
            return { path: ['table', table, idx], type: 'delete', mutation: record };
          }
        }
      }
      break;
    }
    case 'PROFILE_EVENT': {
      return { path: ['profile'], type: 'set', mutation: eventData };
    }
    default: {
      return null;
    }
  }
}
