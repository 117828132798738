import batman from 'url:./batman.png'
import black_panther from 'url:./black_panther.png'
import flash from 'url:./flash.png'
import hulk from 'url:./hulk.png'
import iron_man from 'url:./iron_man.png'
import spiderman from 'url:./spiderman.png'
import wolverine from 'url:./wolverine.png'

import batwoman from 'url:./batwoman.png'
import black_widow from 'url:./black_widow.png'
import captain_marvel from 'url:./captain_marvel.png'
import she_hulk from 'url:./she-hulk.png'
import spidergwen from 'url:./spidergwen.png'
import storm from 'url:./storm.png'

export default {
  flash,

  batman,
  black_panther,
  hulk,
  iron_man,
  spiderman,
  wolverine,

  batwoman,
  black_widow,
  captain_marvel,
  'she-hulk': she_hulk,
  spidergwen,
  storm,
}
