import React from 'react'

import './privacyPolicy.css';

import { Language } from '../../i18n';
import EN from './en';
import NL from './nl';

export const PrivacyPolicy = (
  { lang }: { lang: Language }
) => <div className='privacy-policy'>
  { lang === 'nl' ? <NL /> : <EN /> }
</div>
