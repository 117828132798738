export default {
  common: {
    actions: {
      cancel: 'CANCEL',
      delete: 'DELETE',
      edit: 'EDIT',
      save: 'SAVE',
      send: 'SEND',
      prompt: 'Are you sure?'
    },
    errors: {
      save_failed: 'SAVING FAILED'
    },
    credits: 'Credits',
    next_training: 'Next training',

    // These are deliberately in Dutch at the time of writing as requested by TIFA
    sub_end_warning_day_one: 'Het abonnement verloopt over {{count}} dag.',
    sub_end_warning_day_other: 'Het abonnement verloopt over {{count}} dagen.',
    sub_end_warning_week: 'Het abonnement verloopt over {{count}} weken.',
    sub_end_warning_message: ' Neem contact op met TIFA Sports om te verlengen en door te ontwikkelen!'
  },

  login: {
    change_pass: 'Change password',
    confirm_change_pass: `If the email address is known, you've received a recovery mail`,
    forgot_pass: 'Forgot password?',
    go_back: 'Back to login',
    persist: 'Stay logged in',
  },

  user: {
    fields: {
      name: 'FIRST NAME',
      last_name: 'LAST NAME',
      birth_date: 'DATE OF BIRTH',
      phone_nr: 'PHONE NUMBER',
      team: 'CLUB / TEAM',
      language: {
        _label: 'LANGUAGE',
        en: 'English',
        nl: 'Nederlands'
      },
      avatar: 'PROFILE PICTURE',
    },
    change_pass: 'CHANGE PASSWORD',
  },
  
  slot: {
    label: 'BOOKINGS',

    actions: {
      book: 'BOOK SLOT',
      cancel: 'CANCEL SLOT'
    },

    available: 'SLOTS',
    booked: 'BOOKED',
    no_refund: 'Your credit will not be refunded'
  },

  video: {

  },

  rules: {

  },

  feedback: {
    fields: {
      type: {
        _label: '',
        _empty: 'Choose a category...',
        Bug: 'Bug',
        Design: 'Design',
        Functionaliteit: 'Functionality',
        Overig: 'Other...'
      }
    },
    descriptions: {
      Bug: 'Did you discover a problem in the app? Please let us know - as clearly as possible - what happened, and what you expected would happen instead',
      Design: 'Do you have any design tips? Let us know how we can improve!',
      Functionaliteit: 'Is anything missing, or do you have tips on how to improve functionalities? Please let us know!',
      Overig: 'Do you have any tips or suggestions? Let us know!'
    },

    type_choice: 'Choose a kind of feedback:',
    reload_description: 'New feature not available yet?',
    reload_text: 'RELOAD THE APP',
    thanks_text: 'Thanks for your feedback!',
    error_text: 'Something went wrong sending your feedback; please let us know via info@tifasports.com'
  },

  categories: {
    label: 'Categories',
    hero_text: {
      flash: 'A player who wants to get to the Hulk category at lightning speed',
      batman: 'Players of 4 and 5 years old',
      hulk: 'A player that plays below 1st division in Europe, amateur',
      black_panther: 'A player that plays at 1st division in Europe, academy and high level amateur',
      wolverine: 'A player that plays at the Superleague in The Netherlands, academy',
      spiderman: 'A top player that plays at Feyenoord, Ajax, PSV, AZ in the Superleague, academy',
      iron_man: 'A top player that plays for the national team, academy'
    }
  },

  about: {
    label: 'About us...'
  }
}

