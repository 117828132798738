import React from 'react';

export default () => <><p>
<b>TIFA Sports</b>, gevestigd aan Spinozaweg 498 3076 ET Rotterdam The Netherlands, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
  </p>

  <h3>Contactgegevens:</h3>
  <p>

    https://www.tifasports.com/<br />
    Spinozaweg 498<br />
    3076 ET Rotterdam<br />
    The Netherlands<br />
  </p>

  <p>
Elham Rezaie is de Functionaris Gegevensbescherming van TIFA Sports. Zij is te bereiken via elly_rezaie@hotmail.com
  </p>

  <h3>
Persoonsgegevens die wij verwerken
  </h3>
  <p>
TIFA Sports verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.<br />

Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
  <ul>
    <li>Voor- en achternaam</li>
    <li>Geboortedatum</li>
    <li>Telefoonnummer</li>
    <li>E-mailadres</li>
    <li>Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
  </ul>
</p>

<h3>
Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
</h3>
<p>
TIFA Sports verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van jou:
<ul>
  <li>gegevens van personen jonger dan 16 jaar</li>
</ul>
Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@tifagency.com, dan verwijderen wij deze informatie.
</p>

<h3>
Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
</h3>
<p>
TIFA Sports verwerkt jouw persoonsgegevens voor de volgende doelen:
<ul>
  <li>Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>

  <li>Je de mogelijkheid te bieden een account aan te maken</li>
</ul>
</p>

<h3>
Hoe lang we persoonsgegevens bewaren
</h3>
<p>
TIFA Sports bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij bewaren je persoonsgegevens alleen gedurende je lidmaatschap.
</p>

<h3>
Delen van persoonsgegevens met derden
</h3>
<p>
TIFA Sports verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.
</p>
  
<h3>
Cookies, of vergelijkbare technieken, die wij gebruiken
</h3>
<p>
TIFA Sports gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.
</p>

<h3>
Gegevens inzien, aanpassen of verwijderen
</h3>
<p>
Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kun je zelf doen via de persoonlijke instellingen van jouw account. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door ons bedrijf en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.
Wil je gebruik maken van je recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heb je andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar info@tifagency.com.
Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. TIFA Sports zal zo snel mogelijk, maar in ieder geval binnen vier weken, op jouw verzoek reageren.
</p>
<p>
TIFA Sports wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
</p>

<h3>
Hoe wij persoonsgegevens beveiligen
</h3>
<p>
TIFA Sports neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice of via info@tifagency.com. TIFA Sports heeft de volgende maatregelen genomen om jouw persoonsgegevens te beveiligen:
<ul>
 <li>TLS (voorheen SSL): Wij versturen jouw gegevens via een beveiligde internetverbinding. Dit kun je zien aan de adresbalk 'https' en het hangslotje in de adresbalk.</li>
 <li>Authenticatie: Je krijgt een persoonlijke gebruikers-account wanneer je lid bent. Deze account geeft toegang tot alleen je persoonlijke content. Gebruikers-accounts hebben, door afscherming via de database en server, alleen toegang tot persoonlijke gegevens horend bij deze account.</li>
</ul>
</p></>;
